import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveBar } from '@nivo/bar';
import { BasicTooltip } from '@nivo/tooltip'
import maxBy from 'lodash/maxBy';
import round from 'lodash/round';
import appConfig from "./config/app.config";
import { defaultColorPalette, minAxisBottomTickValue } from './utils/constants';
import Alert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';

const CompanyOverview = (props) => {
    const apiUrl = `${appConfig.dashboardApi.baseUrl}/company-overview`;

    const [error, setError] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [reports, setReports] = React.useState(null);
    const [numberOfProjectsBySectorMaxValue, setNumberOfProjectsBySectorMaxValue] = React.useState('auto');

    React.useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                accessToken: props.accessToken,
                region: props.region,
                branchOfficeId: props.branchOfficeId,
            })
        };

        fetch(apiUrl, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(response.status);
                }
                return response.json();
            })
            .then(
                (result) => {
                    const maxNumberOfProjectsBySector = maxBy(result.reports.numberOfProjectsBySector, 'value');
                    if (maxNumberOfProjectsBySector && maxNumberOfProjectsBySector.value <= minAxisBottomTickValue) {
                        setNumberOfProjectsBySectorMaxValue(minAxisBottomTickValue);
                    }

                    setReports(result.reports);
                    setIsLoaded(true);
                },
                (error) => {
                    setError("Unauthorised Access");
                    setIsLoaded(true);
                }
            )
    }, [props, apiUrl]);

    React.useEffect(() => {
        const updateWindowDimensions = () => {
            const newHeight = document.getElementById("companyOverviewContent")?.offsetHeight;
            window.parent.postMessage(`companyOverviewHeight=${newHeight}`, "*")
        };
      
        window.addEventListener("resize", updateWindowDimensions);
        return () => window.removeEventListener("resize", updateWindowDimensions)
    }, []);

    if (error) {
        return <Typography variant="h6">{error}</Typography>
    } else if (!isLoaded) {
        return (
            <Box sx={{ width: '100%' }} id="companyOverviewContent" ref={(a) => {
                setTimeout(() => {
                    const newHeight = a?.offsetHeight;
                    if (newHeight) {
                        window.parent.postMessage(`companyOverviewHeight=${newHeight}`, "*")
                    }
                }, 1000);
            }}>
                <Skeleton height={35} variant="text" />
                <Skeleton height={35} variant="text" />
                <Skeleton height={35} variant="text" />
            </Box>
        );
    } else {
        return (
            <Box overflow="hidden" id="companyOverviewContent" ref={(a) => {
                setTimeout(() => {
                    const newHeight = a?.offsetHeight;
                    if (newHeight) {
                        window.parent.postMessage(`companyOverviewHeight=${newHeight}`, "*")
                    }
                }, 1000);
            }}>
                {/* <Typography variant="h5" component="h5" sx={{ m: 3, }}>{props.name}</Typography> */}
                <Alert severity="info" sx={{mb: 2}}>
                    <Typography variant="body1" color="textPrimary">
                        Company Overview shows all projects linked to a company over the last five years; it is not limited by your subscription parameters.
                    </Typography>
                </Alert>
                <Grid container spacing={2}>
                    <Grid item container spacing={2} xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Grid item xs={6} sx={{ pt: 2, }}>
                            <Stack direction="column" justifyContent="center" sx={{ width: '100%', height: '100%', border: 1, borderColor: 'grey.500', borderRadius: 2, background: '#03989E', color: '#ffffff', }}>
                                <Typography variant="subtitle2" component="h6" align="center">Number of Projects</Typography>
                                <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                                    <Typography variant="h6" component="h4">{reports.numberOfTotalProjects}</Typography>
                                    <Typography variant="subtitle2" component="h6">({reports.numberOfActiveProjects} Active)</Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item xs={6} sx={{ pt: 2, }}>
                            <Box sx={{ width: '100%', height: '100%', border: 1, borderColor: 'grey.500', borderRadius: 2, }}>
                                <Box sx={{ p: 2, }}>
                                    <Typography
                                        variant="subtitle2"
                                        component="h6"
                                        sx={{ borderBottom: 1, borderColor: 'grey.300', pt: 0, pb: 1, }}
                                    >
                                        Stage Distribution
                                    </Typography>
                                </Box>
                                <Box sx={{ width: '100%', height: '100%', textAlign: 'center', }}>
                                    <ResponsivePie
                                        data={reports.stageDistribution}
                                        animate={true}
                                        isInteractive={true}
                                        activeOuterRadiusOffset={6}
                                        enableArcLabels={true}
                                        arcLabelsSkipAngle={18}
                                        enableArcLinkLabels={false}
                                        arcLinkLabelsSkipAngle={18}
                                        innerRadius={0.6}
                                        padAngle={0.5}
                                        cornerRadius={5}
                                        width={300}
                                        height={120}
                                        margin={{ top: 5, right: 5, bottom: 10, left: -170 }}
                                        valueFormat={value => value + '%'}
                                        legends={[
                                            {
                                                anchor: 'right',
                                                direction: 'column',
                                                justify: false,
                                                translateX: -20,
                                                translateY: 0,
                                                itemWidth: 150,
                                                itemHeight: 16,
                                                itemsSpacing: 8,
                                                itemTextColor: '#000000',
                                                itemDirection: 'left-to-right',
                                                itemOpacity: 1,
                                                symbolSize: 16,
                                                symbolShape: 'circle',
                                            }
                                        ]}
                                        colors={defaultColorPalette}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6} sx={{ pt: 2, }}>
                            <Stack direction="column" justifyContent="center" sx={{ width: '100%', height: '100%', border: 1, borderColor: 'grey.500', borderRadius: 2, background: '#03989E', color: '#ffffff', }}>
                                <Typography variant="subtitle2" component="h6" align="center">Average Project Value</Typography>
                                <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                                    <Typography variant="h6" component="h4">{round(reports.averageProjectValue, 3)}M</Typography>
                                    <Typography variant="subtitle2" component="h6">({props.currency})</Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item xs={6} sx={{ pt: 2, }}>
                            <Box sx={{ width: '100%', height: '100%', border: 1, borderColor: 'grey.500', borderRadius: 2, }}>
                                <Box sx={{ p: 2, }}>
                                    <Typography
                                        variant="subtitle2"
                                        component="h6"
                                        sx={{ borderBottom: 1, borderColor: 'grey.300', pt: 0, pb: 1, }}
                                    >
                                        Value Distribution
                                    </Typography>
                                </Box>
                                <Box sx={{ width: '100%', height: '100%', textAlign: 'center', }}>
                                    <ResponsivePie
                                        data={reports.valueDistribution}
                                        animate={true}
                                        isInteractive={true}
                                        activeOuterRadiusOffset={6}
                                        enableArcLabels={true}
                                        arcLabelsSkipAngle={18}
                                        enableArcLinkLabels={false}
                                        arcLinkLabelsSkipAngle={18}
                                        innerRadius={0.6}
                                        padAngle={0.5}
                                        cornerRadius={5}
                                        width={300}
                                        height={120}
                                        margin={{ top: 5, right: 5, bottom: 10, left: -170 }}
                                        valueFormat={value => value + '%'}
                                        legends={[
                                            {
                                                anchor: 'right',
                                                direction: 'column',
                                                justify: false,
                                                translateX: -20,
                                                translateY: 0,
                                                itemWidth: 150,
                                                itemHeight: 16,
                                                itemsSpacing: 8,
                                                itemTextColor: '#000000',
                                                itemDirection: 'left-to-right',
                                                itemOpacity: 1,
                                                symbolSize: 16,
                                                symbolShape: 'circle',
                                            }
                                        ]}
                                        colors={defaultColorPalette}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2} xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Grid item xs={12} sx={{ pt: 2, }}>
                            <Box sx={{ width: '100%', height: '100%', border: 1, borderColor: 'grey.500', borderRadius: 2, }}>
                                <Box sx={{ p: 2, }}>
                                    <Typography
                                        variant="subtitle2"
                                        component="h6"
                                        sx={{ borderBottom: 1, borderColor: 'grey.300', pt: 0, pb: 1, }}
                                    >
                                        Categories
                                    </Typography>
                                </Box>
                                <Box sx={{ width: '100%', height: '100%', textAlign: 'center', }}>
                                    <ResponsiveBar
                                        data={reports.numberOfProjectsBySector}
                                        indexBy="id"
                                        keys={['value',]}
                                        maxValue={numberOfProjectsBySectorMaxValue}
                                        valueFormat=" >-,"
                                        layout="horizontal"
                                        colorBy="indexValue"
                                        valueScale={{ type: 'linear', }}
                                        indexScale={{ type: 'band', round: true, }}
                                        width={600}
                                        height={360}
                                        margin={{ top: 0, right: 30, bottom: 60, left: 120, }}
                                        tooltip={({ color, formattedValue, indexValue }) => <BasicTooltip id={indexValue} value={formattedValue} enableChip={true} color={color} />}
                                        animate={true}
                                        isInteractive={true}
                                        axisTop={null}
                                        axisRight={null}
                                        axisBottom={{
                                            format: " >-,",
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: 'Number of Projects',
                                            legendPosition: 'middle',
                                            legendOffset: 40,
                                        }}
                                        axisLeft={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: '',
                                            legendPosition: 'middle',
                                            legendOffset: 0
                                        }}
                                        enableGridX={true}
                                        enableGridY={false}
                                        colors={defaultColorPalette}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

export default CompanyOverview;