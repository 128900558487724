export const isProduction = process.env.REACT_APP_NODE_ENV === "PRODUCTION";
export const isDemo = process.env.REACT_APP_NODE_ENV === "DEMO";
export const isStaging = process.env.REACT_APP_NODE_ENV === "STAGING";

export const defaultColorPalette = [
  "#FF9F9F",
  "#6082FF",
  "#FF4040",
  "#13F1F1",
  "#808080",
  "#BFCDFF",
  "#9AF9F9",
  "#E8E8E8",
  "#0CD2D2",
  "#FFDFDF",
  "#DFE6FF",
]

export const tnlmBaseUrl = {
  staging: "https://staging.bci-tnlm.com",
  demo: "https://demo.bci-tnlm.com",
  production: "https://app-leadmanager.bcicentral.com",
}

export const minAxisBottomTickValue = 10;