import React from 'react';
import {
    BrowserRouter as Router,
    useLocation
} from 'react-router-dom';
import {
    createTheme,
    ThemeProvider
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import RelationshipMapping from './RelationshipMapping';
import CompanyOverview from './CompanyOverview';
import { generateTNLMUrl } from './utils/globalFunc';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#FF0000',
            dark: '#FFE8E8',
            light: '#E50000',
        },
        secondary: {
            main: '#808080', // grey-cool-bci
            dark: '#454550', // black-bci
            light: '#E8E8E8', // grey-regular-bci
            contrastText: '#F7F7F7', // grey-light-bci
        },
        info: {
            main: '#0039FF', // blue-bci
        },
        warning: {
            main: '#03989E', //tortoise-bci,
            light: '#D8F4F5', //tortoise-light-bci
        },
        success: {
            main: '#0DA738', //tortoise-bci,
            light: '#0DA738', //tortoise-light-bci
        },
        text: {
            primary: '#454550', // black-bci
        },
        error: {
            main: '#FDA51A',
        },
    },
    typography: {
        h4: {
            fontWeight: 900,
        },
        h5: {
            fontWeight: 900,
        },
        subtitle1: {
            fontSize: "1.125rem", //18px
            fontWeight: 700,
        },
        subtitle2: {
            fontSize: "1rem" //16px
        },
        body1: {
            fontSize: "0.875rem" //14px
        },
        caption: {
            fontSize: "0.75rem" //12px
        }
    },

    components: {
       MuiAutocomplete: {
           styleOverrides: {
               inputRoot: {
                   padding: "0"
               }
           }
       },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    height: "22px",
                    padding: "7px 10px !important",
                    fontSize: "14px"
                }
            }
        },
        MuiAlert: {
            styleOverrides: {
                standard: {
                    borderRadius: "8px",
                    padding: "0 8px"
                },
                standardSuccess: {
                    backgroundColor: "#FFF1E6",
                },
                standardInfo: {
                    backgroundColor: "rgba(0, 57, 255, 0.05)",
                    border: "1px solid rgba(0, 57, 255, 0.1)",
                },
            }
        }
    }
});

const Layout = () => {
    const reportName = useQuery().get('name');
    const reportCurrency = useQuery().get('currency');
    const reportRegion = useQuery().get('region');
    const username = useQuery().get('username');
    const branchOfficeId = parseInt(useQuery().get('companyId'));
    const accessToken = useQuery().get('key');
    const subscriberId = useQuery().get('subscriberId');
    const tnlmEnv = useQuery().get('env');
    const ReportComponent = props => {
        switch (reportName?.toLowerCase()) {
            case 'relationship mapping':
                return <RelationshipMapping
                    name="Relationship Mapping"
                    region={reportRegion}
                    branchOfficeId={branchOfficeId}
                    currency={reportCurrency}
                    username={username}
                    accessToken={accessToken}
                    subscriberId={subscriberId}
                    tnlmUrl={generateTNLMUrl(tnlmEnv)}
                />;
            case 'company overview':
                return <CompanyOverview
                    name="Company Overview"
                    region={reportRegion}
                    branchOfficeId={branchOfficeId}
                    currency={reportCurrency}
                    username={username}
                    accessToken={accessToken}
                />;
            default:
                return null;
        }
    }

    return (
        <Container maxWidth="false">
            <ReportComponent />
        </Container>
    );
}

const Embed = () => {
    if (window.location.pathname === '/embed') {
        return (
            <Router>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Layout />
                </ThemeProvider>
            </Router>
        );
    } else {
        return null;
    }
}

export default Embed;