import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Checkbox from '@mui/material/Checkbox';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { visuallyHidden } from '@mui/utils';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ResponsivePie } from '@nivo/pie';
import appConfig from "./config/app.config";
import { defaultColorPalette } from './utils/constants';
import { Alert, Skeleton } from '@mui/material';

const RelationshipMapping = (props) => {
    const apiUrl = `${appConfig.dashboardApi.baseUrl}/relationship-mapping`;
    const partnerNetworkApiUrl = `${appConfig.tnlmApi.endpoint}${props?.region === "ASIA" ? "/asia" : "/anz"}/api/v2/main/external/partner-network`;
    const partnerNetworkApiKey = appConfig.tnlmApi.apiKey;
    const dense = false;
    const rowsPerPage = 5;

    const [error, setError] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [roleGroups, setRoleGroups] = React.useState([]);
    const [minYear, setMinYear] = React.useState(null);
    const [maxYear, setMaxYear] = React.useState(null);
    const [states, setStates] = React.useState([]);
    const [sectors, setSectors] = React.useState([]);
    const [minValue, setMinValue] = React.useState(null);
    const [maxValue, setMaxValue] = React.useState(null);
    const [developmentTypes, setDevelopmentTypes] = React.useState([]);
    const [commonProjects, setCommonProjects] = React.useState([]);
    const [marketShare, setMarketShare] = React.useState([]);
    const [selectedRoleGroup, setSelectedRoleGroup] = React.useState(null);
    const [roleOptions, setRoleOptions] = React.useState([]);
    const [selectedRole, setSelectedRole] = React.useState(null);
    const [selectedYear, setSelectedYear] = React.useState([]);
    const [selectedState, setSelectedState] = React.useState([]);
    const [selectedSector, setSelectedSector] = React.useState([]);
    const [subSectorOptions, setSubSectorOptions] = React.useState([]);
    const [selectedSubSector, setSelectedSubSector] = React.useState([]);
    const [selectedValue, setSelectedValue] = React.useState([]);
    const [selectedDevelopmentType, setSelectedDevelopmentType] = React.useState([]);
    const [filteredCommonProjects, setFilteredCommonProjects] = React.useState([]);
    const [filteredMarketShare, setFilteredMarketShare] = React.useState([]);
    const [applyingFilters, setApplyingFilters] = React.useState(false);
    const [linkingPartner, setLinkingPartner] = React.useState(false);
    const [backdropOpen, setBackdropOpen] = React.useState(false);
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('value');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [alertDialogOpen, setAlertDialogOpen] = React.useState(false);
    const [alertDialogTitle, setAlertDialogTitle] = React.useState(null);
    const [alertDialogContent, setAlertDialogContent] = React.useState(null);

    const headCells = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Partner',
            width: '64%',
        },
        {
            id: 'value',
            numeric: true,
            disablePadding: true,
            label: 'Number of Common Projects',
            width: '32%',
        },
    ];

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    const getComparator = (order, orderBy) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const EnhancedTableHead = (props) => {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
            props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox" sx={{ width: '4%', }}>
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                        />
                    </TableCell>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                            sx={{ width: headCell.width, }}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
    };

    const EnhancedTableToolbar = (props) => {
        const { numSelected } = props;

        return (
            <Toolbar
                sx={{
                    pl: { sm: 0 },
                    pr: { xs: 1, sm: 1 },
                    width: '100%'
                }}
            >
                {numSelected > 0 ? (
                    <LoadingButton
                        loading={linkingPartner}
                        loadingIndicator="Linking..."
                        size="medium"
                        variant="contained"
                        sx={{ textTransform: 'capitalize', }}
                        onClick={async () => {
                            setBackdropOpen(true);
                            setLinkingPartner(true);
                            const requestOptions = {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${props.accessToken}`,
                                    'x-subscriber-id': `${props.subscriberId}`,
                                    'X-Api-Key': partnerNetworkApiKey,
                                },
                                body: JSON.stringify({
                                    username: props.username,
                                    companyId: props.branchOfficeId,
                                    partnerId: selected,
                                }),
                            };
                            try {
                                const response = await fetch(partnerNetworkApiUrl, requestOptions);
                                const responseData = await response.json();
                                let messageTitle = null;
                                let messageContent = null;
                                if (responseData.data.attributes.success) {
                                    setSelected([]);
                                    window.parent.postMessage("error=false", "*")
                                    messageTitle = 'Success';
                                    messageContent = `Selected ${selected.length > 1 ? 'partners have' : 'partner has'} been linked successfully.`
                                } else {
                                    window.parent.postMessage("error=true", "*")
                                    setAlertDialogTitle('Error');
                                    setAlertDialogContent('System is busy now, please try again later.');
                                    setAlertDialogOpen(true);
                                }
                                setLinkingPartner(false);
                                setBackdropOpen(false);
                                setAlertDialogTitle(messageTitle);
                                setAlertDialogContent(messageContent);
                            } catch (e) {
                                window.parent.postMessage("error=true", "*")
                                setLinkingPartner(false);
                                setBackdropOpen(false);
                                setAlertDialogTitle('Error');
                                setAlertDialogContent('System is busy now, please try again later.');
                                setAlertDialogOpen(true);
                            }
                        }}
                    >
                        Link {numSelected} Selected Partners
                    </LoadingButton>
                ) : null}
            </Toolbar>
        );
    };

    EnhancedTableToolbar.propTypes = {
        numSelected: PropTypes.number.isRequired,
        accessToken: PropTypes.string.isRequired,
        subscriberId: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired,
        branchOfficeId: PropTypes.number.isRequired,
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredCommonProjects.length) : 0;

    React.useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                accessToken: props.accessToken,
                region: props.region,
                branchOfficeId: props.branchOfficeId
            })
        };

        fetch(apiUrl, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(response.status);
                }
                return response.json();
            })
            .then(
                (result) => {
                    let filterMinYear = result.filters.minPublishedYear;
                    let filterMaxYear = result.filters.maxPublishedYear;
                    if (filterMaxYear === filterMinYear) {
                        filterMaxYear += 1;
                    }
                    let filterMinValue = result.filters.minValue;
                    let filterMaxValue = result.filters.maxValue;
                    if (filterMaxValue === filterMinValue) {
                        filterMaxValue += 1;
                    }
                    result.filters.sectors.forEach(sector => {
                        sector.subSectors.forEach(subSector => {
                            subSector.parent_id = sector.id;
                            subSector.parent_label = sector.label;
                        });
                    });
                    setMinYear(filterMinYear);
                    setMaxYear(filterMaxYear);
                    setMinValue(filterMinValue);
                    setMaxValue(filterMaxValue);
                    setStates(result.filters.states);
                    setDevelopmentTypes(result.filters.developmentTypes);
                    setRoleGroups(result.filters.roleGroups);
                    setSectors(result.filters.sectors)
                    setCommonProjects(result.reports.commonProjects);
                    setMarketShare(result.reports.marketShare);
                    setFilteredCommonProjects(result.reports.commonProjects);
                    setFilteredMarketShare(result.reports.marketShare);
                    setSelectedYear([filterMinYear, filterMaxYear]);
                    setSelectedValue([filterMinValue, filterMaxValue])
                    setIsLoaded(true);
                },
                (error) => {
                    setError("Unauthorised Access");
                    setIsLoaded(true);
                }
            )
    }, [props, apiUrl]);

    React.useEffect(() => {
        const updateWindowDimensions = () => {
            const newHeight = document.getElementById("relationshipMappingContent")?.offsetHeight;
            window.parent.postMessage(`relationshipMappingHeight=${newHeight}`, "*")
        };
      
        window.addEventListener("resize", updateWindowDimensions);
        return () => window.removeEventListener("resize", updateWindowDimensions)
    }, []);

    if (error) {
        return <Typography variant="h6">{error}</Typography>
    } else if (!isLoaded) {
        return (
            <Box sx={{ width: '100%' }} id="relationshipMappingContent" ref={(a) => {
                const newHeight = a?.offsetHeight;
                if (newHeight) {
                    window.parent.postMessage(`relationshipMappingHeight=${newHeight}`, "*")
                }
            }}>
                <Skeleton height={35} variant="text" />
                <Skeleton height={35} variant="text" />
                <Skeleton height={35} variant="text" />
            </Box>
        );
    } else {
        return (
            <Box overflow="hidden" id="relationshipMappingContent" ref={(a) => {
                const newHeight = a?.offsetHeight;
                if (newHeight) {
                    window.parent.postMessage(`relationshipMappingHeight=${newHeight}`, "*")
                }
            }}>
                {/* <Typography variant="h5" component="h5" sx={{ m: 3, }}>{props.name}</Typography> */}
                <Alert severity="info" sx={{mb: 2}}>
                    <Typography variant="body1" color="textPrimary">
                        Relationship Mapping shows all projects linked to a company and its partners over the last five years; it is not limited by your subscription/search parameters.
                    </Typography>
                </Alert>
                <Box sx={{ border: 1, borderColor: 'grey.500', borderRadius: 2, my: 3, pt: 1, px: 3, pb: 3, }}>
                    <Typography variant="subtitle2" sx={{ mt: 1, mb: 3, }}>Filter</Typography>
                    <Grid container rowSpacing={6} columnSpacing={1}>
                        <Grid item xs={12} sm={6} md={2.66} lg={2.66} xl={2.66}>
                            <Typography variant="body1" component="div">
                                Partner Role Group
                            </Typography>
                            <Autocomplete
                                filterSelectedOptions
                                id="filter-partner-role-group"
                                options={roleGroups}
                                value={selectedRoleGroup}
                                onChange={(event, newValue) => {
                                    setSelectedRoleGroup(newValue);
                                    setRoleOptions(newValue?.roles || []);
                                    setSelectedRole(null);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Please pick a role group"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2.66} lg={2.66} xl={2.66}>
                            <Typography variant="body1" component="div">
                                Partner Role
                            </Typography>
                            <Autocomplete
                                filterSelectedOptions
                                id="filter-partner-role"
                                options={roleOptions}
                                groupBy={(option) => option.parent_label}
                                value={selectedRole}
                                onChange={(event, newValue) => {
                                    setSelectedRole(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Please pick a role"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                            <Typography variant="body1" component="div">
                                Year
                            </Typography>
                            <Stack
                                direction="column"
                                spacing={2}
                                sx={{ position: 'relative', px: 1.5 }}
                            >
                                <Box display="flex" justifyContent="space-between" mt={-0.3} mx={-1.2}>
                                    <Typography variant="caption">{selectedYear[0]}</Typography>
                                    <Typography variant="caption">{selectedYear[1]}</Typography>
                                </Box>
                                {/* <TextField
                                    type="number"
                                    value={selectedYear[0]}
                                    size="medium"
                                    variant="standard"
                                    onChange={(event) => {
                                        const newMinYear = parseInt(event.target.value);
                                        if (!isNaN(newMinYear)) {
                                            setSelectedYear([newMinYear, selectedYear[1]]);
                                        }
                                    }}
                                    onBlur={() => {
                                        const newMinYear = Math.min(Math.max(minYear, selectedYear[0]), selectedYear[1]);
                                        const newMaxYear = Math.min(maxYear, selectedYear[1]);
                                        setSelectedYear([newMinYear, newMaxYear]);
                                    }}
                                    inputProps={{
                                        step: 1,
                                        min: minYear,
                                        max: maxYear,
                                    }}
                                    sx={{ minWidth: 75, }}
                                /> */}
                                <Slider
                                    sx={{mt: "-5px !important"}}
                                    id="filter-year"
                                    min={minYear}
                                    max={maxYear}
                                    step={1}
                                    value={selectedYear}
                                    size="medium"
                                    onChange={(event, newValue) => {
                                        setSelectedYear(newValue);
                                    }}
                                    valueLabelDisplay="off"
                                    disableSwap
                                />
                                {/* <TextField
                                    type="number"
                                    value={selectedYear[1]}
                                    size="medium"
                                    variant="standard"
                                    onChange={(event) => {
                                        const newMaxYear = parseInt(event.target.value);
                                        if (!isNaN(newMaxYear)) {
                                            setSelectedYear([selectedYear[0], newMaxYear]);
                                        }
                                    }}
                                    onBlur={() => {
                                        const newMinYear = Math.max(minYear, selectedYear[0]);
                                        const newMaxYear = Math.max(Math.min(maxYear, selectedYear[1]), selectedYear[0]);
                                        setSelectedYear([newMinYear, newMaxYear]);
                                    }}
                                    inputProps={{
                                        step: 1,
                                        min: minYear,
                                        max: maxYear,
                                    }}
                                    sx={{ minWidth: 75, }}
                                /> */}
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2.66} lg={2.66} xl={2.66}>
                            <Typography variant="body1" component="div">
                                State
                            </Typography>
                            <Autocomplete
                                multiple
                                filterSelectedOptions
                                id="filter-state"
                                options={states}
                                value={selectedState}
                                onChange={(event, newValue) => {
                                    setSelectedState(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Please pick a state"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2.66} lg={2.66} xl={2.66}>
                            <Typography variant="body1" component="div">
                                Sector
                            </Typography>
                            <Autocomplete
                                multiple
                                filterSelectedOptions
                                id="filter-sector"
                                options={sectors}
                                value={selectedSector}
                                onChange={(event, newValue) => {
                                    const newSubSectorOptions = newValue.map(e => e.subSectors).reduce((previous, current) => previous.concat(current), []);
                                    const newSubSectorOptionIdSet = newSubSectorOptions.reduce((previous, current) => { previous[current.id] = null; return previous }, {});
                                    const newSelectedSubSector = selectedSubSector.filter(e => e.id in newSubSectorOptionIdSet);
                                    setSelectedSector(newValue);
                                    setSubSectorOptions(newSubSectorOptions);
                                    setSelectedSubSector(newSelectedSubSector);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Please pick a sector"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2.66} lg={2.66} xl={2.66}>
                            <Typography variant="body1" component="div">
                                Sub-Sectors
                            </Typography>
                            <Autocomplete
                                multiple
                                filterSelectedOptions
                                id="filter-sub-sector"
                                options={subSectorOptions}
                                groupBy={(option) => option.parent_label}
                                value={selectedSubSector}
                                onChange={(event, newValue) => {
                                    setSelectedSubSector(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Please pick a sub-sector"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                            <Typography variant="body1" component="div">
                                Value (million {props.currency})
                            </Typography>
                            <Stack
                                direction="column"
                                spacing={2}
                                sx={{ position: 'relative', px: 1.5 }}
                            >
                                <Box display="flex" justifyContent="space-between" mt={-0.3} mx={-1.2}>
                                    <Typography variant="caption">{Number(selectedValue[0]).toLocaleString()}</Typography>
                                    <Typography variant="caption">{Number(selectedValue[1]).toLocaleString()}</Typography>
                                </Box>
                                {/* <TextField
                                    type="number"
                                    value={selectedValue[0]}
                                    size="medium"
                                    variant="standard"
                                    onChange={(event) => {
                                        const newMinValue = parseInt(event.target.value);
                                        if (!isNaN(newMinValue)) {
                                            setSelectedValue([newMinValue, selectedValue[1]]);
                                        }
                                    }}
                                    onBlur={() => {
                                        const newMinValue = Math.min(Math.max(minValue, selectedValue[0]), selectedValue[1]);
                                        const newMaxValue = Math.min(maxValue, selectedValue[1]);
                                        setSelectedValue([newMinValue, newMaxValue]);
                                    }}
                                    inputProps={{
                                        step: 1,
                                        min: minValue,
                                        max: maxValue,
                                    }}
                                    sx={{ minWidth: 75, }}
                                /> */}
                                <Slider
                                    sx={{mt: "-5px !important"}}
                                    id="filter-value"
                                    disableSwap
                                    min={minValue}
                                    max={maxValue}
                                    step={1}
                                    value={selectedValue}
                                    size="medium"
                                    onChange={(event, newValue) => {
                                        setSelectedValue(newValue);
                                    }}
                                    valueLabelDisplay="off"
                                />
                                {/* <TextField
                                    type="number"
                                    value={selectedValue[1]}
                                    size="medium"
                                    variant="standard"
                                    onChange={(event) => {
                                        const newMaxValue = parseInt(event.target.value);
                                        if (!isNaN(newMaxValue)) {
                                            setSelectedValue([selectedValue[0], newMaxValue]);
                                        }
                                    }}
                                    onBlur={() => {
                                        const newMinValue = Math.max(minValue, selectedValue[0]);
                                        const newMaxValue = Math.max(Math.min(maxValue, selectedValue[1]), selectedValue[0]);
                                        setSelectedValue([newMinValue, newMaxValue]);
                                    }}
                                    inputProps={{
                                        step: 1,
                                        min: minValue,
                                        max: maxValue,
                                    }}
                                    sx={{ minWidth: 75, }}
                                /> */}
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2.66} lg={2.66} xl={2.66}>
                            <Typography variant="body1" component="div">
                                Development Types
                            </Typography>
                            <Autocomplete
                                multiple
                                filterSelectedOptions
                                id="filter-development-type"
                                options={developmentTypes}
                                value={selectedDevelopmentType}
                                onChange={(event, newValue) => {
                                    setSelectedDevelopmentType(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Please pick a development type"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Stack direction="row" justifyContent="center" spacing={2}>
                    <LoadingButton
                        loading={applyingFilters}
                        loadingIndicator="Applying..."
                        variant="contained"
                        size="large"
                        sx={{ textTransform: 'capitalize', }}
                        onClick={async () => {
                            if (selectedRoleGroup === null && selectedRole === null && selectedYear[0] === minYear && selectedYear[1] === maxYear && selectedState.length === 0 &&
                                selectedSector.length === 0 && selectedSubSector.length === 0 && selectedValue[0] === minValue && selectedValue[1] === maxValue && selectedDevelopmentType.length === 0) {
                                setAlertDialogTitle('Warning');
                                setAlertDialogContent('No filter has been specified.');
                                setAlertDialogOpen(true);
                                return;
                            }
                            setBackdropOpen(true);
                            setApplyingFilters(true);
                            const sectorWithSelectedSubSectorIdSet = selectedSubSector.reduce((previous, current) => { previous[current.parent_id] = null; return previous }, {});
                            const sectorsWithoutSelectedSubSector = selectedSector.filter(e => !(e.id in sectorWithSelectedSubSectorIdSet));
                            const requestOptions = {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify({
                                    accessToken: props.accessToken,
                                    region: props.region,
                                    branchOfficeId: props.branchOfficeId,
                                    filters: {
                                        minPublishedYear: selectedYear[0],
                                        maxPublishedYear: selectedYear[1],
                                        minValue: selectedValue[0],
                                        maxValue: selectedValue[1],
                                        states: selectedState.map(e => e.id),
                                        developmentTypes: selectedDevelopmentType.map(e => e.id),
                                        roles: selectedRole ? [selectedRole.id] : (selectedRoleGroup?.roles || []).map(e => e.id),
                                        subSectors: sectorsWithoutSelectedSubSector.reduce((previous, current) => { return previous.concat(current.subSectors) }, []).map(e => e.id).concat(selectedSubSector.map(e => e.id)),
                                    },
                                }),
                            };
                            try {
                                const response = await fetch(apiUrl, requestOptions);
                                if (!response.ok) {
                                    throw new Error(response.status);
                                }
                                const responseData = await response.json();
                                setFilteredCommonProjects(responseData.reports.commonProjects);
                                setFilteredMarketShare(responseData.reports.marketShare);
                                setApplyingFilters(false);
                                setBackdropOpen(false);
                            } catch (e) {
                                setApplyingFilters(false);
                                setBackdropOpen(false);
                            }
                        }}
                    >
                        Apply Filters
                    </LoadingButton>
                    <Button
                        variant="contained"
                        size="large"
                        sx={{ textTransform: 'capitalize', }}
                        onClick={() => {
                            setSelectedRoleGroup(null);
                            setSelectedRole(null);
                            setSelectedYear([minYear, maxYear]);
                            setSelectedState([]);
                            setSelectedSector([]);
                            setSelectedSubSector([]);
                            setSelectedValue([minValue, maxValue]);
                            setSelectedDevelopmentType([]);
                            setFilteredCommonProjects(commonProjects);
                            setFilteredMarketShare(marketShare);
                            setRoleOptions([]);
                            setSubSectorOptions([])
                        }}
                    >
                        Clear Filters
                    </Button>
                </Stack>
                <Box sx={{ my: 3, py: 2, pr: 0, }}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}
                            sx={{
                                pr: 1,
                                mb: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0, }, 
                            }}>
                                <Box sx={{ width: '100%', height: '100%', border: 1, borderColor: 'grey.500', borderRadius: 2, }}>
                                    <Box sx={{ p: 2, }}>
                                        <Typography
                                            variant="subtitle2"
                                            sx={{ borderBottom: 1, borderColor: 'grey.300', p: 2, }}
                                        >
                                            Common Projects with Partners
                                        </Typography>
                                    </Box>
                                    <Box sx={{ p: 2, }}>
                                        <TableContainer sx={{ overflow: "hidden" }}>
                                            <Table
                                                sx={{ minWidth: "100%" }}
                                                size={'small'}
                                            >
                                                <EnhancedTableHead
                                                    numSelected={selected.length}
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onSelectAllClick={(event) => {
                                                        if (selected.length === 0) {
                                                            const newSelecteds = filteredCommonProjects.sort(getComparator(order, orderBy)).map((n) => n.id);
                                                            setSelected(newSelecteds.slice(page * 5, (page + 1) * 5));
                                                            return;
                                                        }
                                                        setSelected([]);
                                                    }}
                                                    onRequestSort={(event, property) => {
                                                        const isAsc = orderBy === property && order === 'asc';
                                                        setOrder(isAsc ? 'desc' : 'asc');
                                                        setOrderBy(property);
                                                        setSelected([])
                                                    }}
                                                    rowCount={filteredCommonProjects.length}
                                                />
                                                <TableBody>
                                                    {filteredCommonProjects.slice().sort(getComparator(order, orderBy))
                                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        .map((row, index) => {
                                                            const isItemSelected = isSelected(row.id);
                                                            const labelId = `enhanced-table-checkbox-${index}`;

                                                            return (
                                                                <TableRow
                                                                    hover
                                                                    role="checkbox"
                                                                    tabIndex={-1}
                                                                    key={row.id}
                                                                    selected={isItemSelected}
                                                                >
                                                                    <TableCell padding="checkbox">
                                                                        <Checkbox
                                                                            color="primary"
                                                                            checked={isItemSelected}
                                                                            onClick={(event) => handleClick(event, row.id)}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell
                                                                        component="th"
                                                                        id={labelId}
                                                                        scope="row"
                                                                        padding="none"
                                                                        sx={{ pr: 2, }}
                                                                    >
                                                                        <a
                                                                            rel="noreferrer"
                                                                            target="_blank"
                                                                            href={`${props?.tnlmUrl}/main/company/${row.companyId}?filter={}`}
                                                                        >
                                                                            {row.name}
                                                                        </a>
                                                                    </TableCell>
                                                                    <TableCell align="right" sx={{ p: 0, }}>
                                                                        <Button
                                                                            color="warning"
                                                                            side="small"
                                                                            variant="contained"
                                                                            sx={{ width: '100%', borderRadius: 0, py: 1, my: 1, }}
                                                                            target="_blank"
                                                                            href={`${props?.tnlmUrl}/main/common-project?company_id=${props.branchOfficeId}&partner_id=${row.id}&project_id=${row.list}`}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                            }}
                                                                        >
                                                                            {row.value}
                                                                        </Button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                    {emptyRows > 0 && (
                                                        <TableRow
                                                            style={{
                                                                height: (dense ? 33 : 53) * emptyRows,
                                                            }}
                                                        >
                                                            <TableCell colSpan={6} />
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <Box display="flex" alignItems="center">
                                            <Box flex={1}>
                                                <EnhancedTableToolbar
                                                    numSelected={selected.length}
                                                    accessToken={props.accessToken}
                                                    subscriberId={props.subscriberId}
                                                    branchOfficeId={props.branchOfficeId}
                                                    username={props.username}
                                                />
                                            </Box>
                                            <Box>
                                                <TablePagination
                                                    component="div"
                                                    rowsPerPageOptions={[5]}
                                                    count={filteredCommonProjects.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onPageChange={(event, newPage) => {
                                                        setPage(newPage);
                                                        setSelected([]);
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}
                            sx={{
                                pr: 1,
                                pt: '0 !important',
                                mb: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0, },
                            }}>
                            <Box sx={{ width: '100%', height: '100%', border: 1, borderColor: 'grey.500', borderRadius: 2, }}>
                                <Box sx={{ p: 2, }}>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{ borderBottom: 1, borderColor: 'grey.300', p: 2, }}
                                    >
                                        Top Partners Market Share
                                    </Typography>
                                </Box>
                                <Box sx={{ width: '100%', height: '100%', pt: 3, }}>
                                    <ResponsivePie
                                        data={filteredMarketShare}
                                        animate={true}
                                        isInteractive={true}
                                        activeOuterRadiusOffset={6}
                                        enableArcLabels={true}
                                        arcLabelsSkipAngle={18}
                                        enableArcLinkLabels={false}
                                        arcLinkLabelsSkipAngle={18}
                                        innerRadius={0.6}
                                        padAngle={0.5}
                                        cornerRadius={5}
                                        height={400}
                                        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                                        valueFormat={value => value + '%'}
                                        colors={defaultColorPalette}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Dialog
                    open={alertDialogOpen}
                    onClose={() => { setAlertDialogOpen(false); }}
                >
                    <DialogTitle id="alert-dialog-title">{alertDialogTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">{alertDialogContent}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setAlertDialogOpen(false); }} autoFocus>OK</Button>
                    </DialogActions>
                </Dialog>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={backdropOpen}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Box>
        );
    }
}

export default RelationshipMapping;