import { isDemo, isProduction, isStaging } from "../utils/constants";

const appConfig = {
    dashboardApi: {
        baseUrl:
            isProduction ? 'https://apidashboard.bci-tnlm.com/api' :
                isDemo ? 'https://apidashboard.demo.bci-tnlm.com/api' :
                    isStaging ? 'https://apidashboard.staging.bci-tnlm.com/api' :
                        'http://127.0.0.1:5000/api',
    },
    partnerNetworkApi: {
        endpoint:
            isProduction ? 'https://api.bci-tnlm.com/api/v2/main/external/partner-network' :
                isDemo ? 'https://api.demo.bci-tnlm.com/api/v2/main/external/partner-network' :
                    isStaging ? 'https://api.staging.bci-tnlm.com/api/v2/main/external/partner-network' :
                        'https://api.staging.bci-tnlm.com/api/v2/main/external/partner-network',
        apiKey:
            isProduction ? 'nkQfjgIEL4QVuBmoCqWGxXQ6e5zgrT' :
                isDemo ? 'nkQfjgIEL4QVuBmoCqWGxXQ6e5zgrT' :
                    isStaging ? 'nkQfjgIEL4QVuBmoCqWGxXQ6e5zgrT' :
                        'nkQfjgIEL4QVuBmoCqWGxXQ6e5zgrT',
    },
    tnlmApi: {
        endpoint:
            isProduction ? 'https://api.bci-tnlm.com' :
                isDemo ? 'https://api.demo.bci-tnlm.com' :
                    isStaging ? 'https://api.staging.bci-tnlm.com' :
                        'https://api.staging.bci-tnlm.com',
        apiKey:
            isProduction ? 'nkQfjgIEL4QVuBmoCqWGxXQ6e5zgrT' :
                isDemo ? 'nkQfjgIEL4QVuBmoCqWGxXQ6e5zgrT' :
                    isStaging ? 'nkQfjgIEL4QVuBmoCqWGxXQ6e5zgrT' :
                        'nkQfjgIEL4QVuBmoCqWGxXQ6e5zgrT',
    },
}

export default appConfig;